
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { laborDataIndependentInterface } from '@/utils/generalInterface'
  import { mapActions } from 'vuex'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { RULES } from '@/components/forms'
  import { FileParameter } from '@/entities/files'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import GFiles from '@/components/core/files/GFiles.vue'

@Component({
  components: { GFiles, GDatePicker },
  methods: {
    ...mapActions('persons/labor', ['sendLaborIndependent']),
  },
  computed: {},
})
  export default class LaborDataIndependent extends FilesProcess {
  $refs!: {
    form2: HTMLFormElement
  };

  valid = false
  disabledField = true
  fieldRequired = RULES.isRequired
  data: laborDataIndependentInterface = {
    rent: '',
    siiKey: '',
    dateEmployment: '',
    retirementLetter: [],
    leaseContract: [],
    commercialPatent: [],
    taxFolder: [],
    comments: '',
  }

  fields = {
    taxFolder: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
        icon: '',
      },
    },
    commercialPatent: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
        icon: '',
      },
    },
    retirementLetter: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
        icon: '',
      },
    },
    leaseContract: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
        icon: '',
      },
    },
  }

  sendLaborIndependent!:
    ({ data, idPerson }: { data: laborDataIndependentInterface, idPerson: number }) => Promise<void>;

  @Prop({ type: Array, default: () => [] }) files!: FileParameter[];
  @Prop({ type: Number, default: null }) idProcess!: number;
  @Prop({ type: Boolean, default: false }) send!: boolean;
  @Prop({ type: Boolean, default: false }) close!: boolean;
  @Prop({ type: Number, default: null }) idPerson!: number
  @Prop({
    type: Object,
    default: () => {
    },
  }) value!: any;

  @Watch('send')
  async onSend (): Promise<void> {
    if (!this.$refs.form2.validate()) {
      this.$emit('send')
      return
    }
    const resp = await this.sendLaborIndependent({ data: this.data, idPerson: this.idPerson })

    if (this.data?.taxFolder?.length) {
      await this.handleFileType(this.data.taxFolder, { properties: this.fields.taxFolder.properties }, this.idProcess, resp.id)
    }

    if (this.data?.commercialPatent?.length) {
      await this.handleFileType(this.data.commercialPatent, { properties: this.fields.commercialPatent.properties }, this.idProcess, resp.id)
    }

    if (this.data?.retirementLetter?.length) {
      await this.handleFileType(this.data.retirementLetter, { properties: this.fields.retirementLetter.properties }, this.idProcess, resp.id)
    }

    if (this.data?.leaseContract?.length) {
      await this.handleFileType(this.data.leaseContract, { properties: this.fields.leaseContract.properties }, this.idProcess, resp.id)
    }

    this.clearLaborInfo()
    this.$emit('close')
  }

  clearLaborInfo (): void {
    this.data = {
      rent: '',
      siiKey: '',
      dateEmployment: '',
      retirementLetter: [],
      leaseContract: [],
      commercialPatent: [],
      taxFolder: [],
      comments: '',
    }
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)

    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  mounted () {
    const { value, files } = this

    this.setProperties(files, 'tax_folder', 'taxFolder')
    this.setProperties(files, 'commercial_patent', 'commercialPatent')
    this.setProperties(files, 'retirement_letter', 'retirementLetter')
    this.setProperties(files, 'lease_contract', 'leaseContract')

    if (value) {
      this.data = {
        dateEmployment: value.admissionDate,
        rent: value.rent,
        id: value.id,
        siiKey: value.jobKey,
        comments: value.executiveComment,
      }

      this.disabledField = true
    } else {
      this.clearLaborInfo()
    }
  }
  }
