
  import { Component } from 'vue-property-decorator'
  import { BaseCustomForm } from '@/components/person/BasicCustomForm'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import DesktopForm from '@/components/forms/view/DesktopForm.vue'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import MobileForm from '@/components/forms/view/MobileForm.vue'
  import { mapActions, mapGetters } from 'vuex'
  import LaborDataIndependent from '@/components/person/laborData/LaborDataIndependent.vue'
  import LaborDataDependent from '@/components/person/laborData/LaborDataDependent.vue'
  import { Details } from '@/utils/generalInterface'

@Component({
  components: { LaborDataDependent, LaborDataIndependent, MobileForm, FormTitle, DesktopForm, GAlert },
  methods: {
    ...mapActions('persons/labor', ['getLaborInfo', 'cleanInfoData']),
  },
  computed: {
    ...mapGetters('persons/labor', [
      'laborDataCreated',
      'laborInfo',
      'laborPersonUpdated',
      'contract', 'activity', 'rent',
    ]),
    ...mapGetters('persons', ['personType']),
  },
})
  export default class LaborDataForm extends BaseCustomForm {
  cleanInfoData!: () => void;
  laborInfo!: any;
  contract!: Details[];
  rent!: Details[];
  activity!: Details[];
  personType!: Details[];
  getLaborInfo!: () => void;
  activityType = ''
  disabledField = false
  comments = ''
  send = false;
  idProcess = null
  files = []

  async mounted () {
    this.getLaborInfo()

    this.files = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'labor_data' } } }] },
    })

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'labor_data' } },
    })

    this.idProcess = process[0].id
  }

  enableLaborForms () {
    if (!this.$refs.form.validate()) return
    this.send = true
  }

  closeForm () {
    this.cleanInfoData()
    this.$router.back()
  }
  }
